@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import 'taures.theme';

@import 'src/app/components/contact-card/contact-card.theme';
@include contact-card-theme($theme);

@import 'src/app/components/cv-entry/cv-entry.theme';
@include cv-entry-theme($theme);

.mat-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

html, body { height: 100%; }
body { margin: 0; font-family: Palanquin, sans-serif; }

.main-container {
  display: block;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  max-width: 100%;
}

.jumbotron {
  background: #fff;
  padding: 20px 0 0 0;
  text-align: center;

  .mat-headline-4, .mat-headline-2 {
    margin-bottom: 30px;
  }

  p {
    font-size: 1.3125rem;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.6875rem;
    color: #827773;
  }
}

.mat-headline-3 {
  margin-bottom: 15px !important;
}

@media (min-width: 576px) {
  .main-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .main-container {
    max-width: 720px;
  }
  .jumbotron {
    margin-top: -220px;
    background: #fff;
    box-shadow: 0 -10px 12px -4px rgba(0, 0, 0, .15);
    padding: 60px 45px 20px 45px;
  }
}

@media (min-width: 992px) {
  .main-container {
    max-width: 960px;
  }
}

.accent-alt {
  background-color: #C1D2E6 !important;
}

strong {
  font-weight: bold;
}

.ql-font-roboto {
  font-family: Palanquin, sans-serif;
}

.ql-editor > * {
  color: black;
  font: 400 1rem/1.3 Palanquin, sans-serif;
}

.mat-mdc-form-field {
  .ql-container {
    // set quill caret to match current Angular Material theme
    caret-color: rgba(black, 0.87);

    // Remove redundant quill snow-theme border
    &.ql-snow {
      border: none;
    }

    // Remove all default quill focus indicators
    .ql-editor {
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  // make sure any quill tooltips, bubbles, or popups aren't occluded
  .ql-tooltip {
    z-index: 1;
  }
}

.mdc-button__label {
  color: white;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 32px !important;
}

.mdc-text-field__input {
  font-weight: 500 !important;
  letter-spacing: 0 !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 2px !important;
}
