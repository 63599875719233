@use '@angular/material' as mat;
@import '@taures/angular-commons/taures.theme';

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Palanquin:300,400,500,600');

$custom-typography-config: mat.define-typography-config(
  $font-family: 'Palanquin, sans-serif',
  $headline-2: mat.define-typography-level(2.875rem, 3.0625rem, 500),
  $headline-3: mat.define-typography-level(2.875rem, 3.0625rem, 400),
  $headline-4: mat.define-typography-level(2.125rem, 2.5rem, 400),
  $headline-5: mat.define-typography-level(1.5rem, 3rem, 400),
  $button: mat.define-typography-level(1rem, 1.3, 600)
);

$new-accent: mat.define-palette((
  main: #f0b323,
  lighter:  #fbe8bd,
  darker: #e99a15,
  contrast : (
    main: $light-primary-text,
    lighter: $light-primary-text,
    darker: $light-primary-text,
  )
), main, lighter, darker);

$theme: mat.define-light-theme((
  color: (
    primary: $taures-primary,
    accent: $new-accent,
    warn: $taures-warn
  ),
  typography: $custom-typography-config,
  density: 0,
));

@include mat.all-component-colors($theme);
@include mat.all-component-typographies($custom-typography-config);

// Change color of headlines
@mixin headline-font-colors($theme) {
  $config: mat.get-color-config($theme);
  $primary: map-get($config, primary);

  .mat-headline-1, .mat-headline-2, .mat-headline-3, .mat-headline-4, .mat-headline-5 {
    color: mat.get-color-from-palette($primary);
  }
  .mat-h1, .mat-headline-5, .mat-typography h1 {
    color: mat.get-color-from-palette($primary);
  }
  .mat-h2, .mat-headline-6, .mat-typography h2 {
    color: mat.get-color-from-palette($primary);
  }
  .mat-h3, .mat-subtitle-1, .mat-typography h3 {
    color: mat.get-color-from-palette($primary);
  }
}

@include headline-font-colors($theme);
