// Import library functions for theme creation.
@use '@angular/material' as mat;
@import 'node_modules/@angular/material/theming';

app-cv-entry {
  // Adds a shadow for elevation level 2 with default color and full opacity:
  @include mat.elevation(2);
}

@mixin cv-entry-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);

  app-cv-entry.light {
    border-color: #b2d2fb;
  }

  app-cv-entry.lighter {
    border-color: #81a1c8;
  }

  app-cv-entry.green {
    border-color: #94cc9e;
  }

  app-cv-entry.primary {
    border-color: mat.get-color-from-palette($primary);
  }

  app-cv-entry.accent {
    border-color: mat.get-color-from-palette($accent);
  }

  app-cv-entry p {
    color: mat.get-color-from-palette($primary);
  }

  app-cv-entry ul {
    color: mat.get-color-from-palette($primary);
  }
}

@mixin cv-entry-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include cv-entry-color($color);
  }
}
