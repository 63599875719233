// Import library functions for theme creation.
@use '@angular/material' as mat;
@import 'node_modules/@angular/material/theming';

app-contact-card {
  // Adds a shadow for elevation level 2 with default color and full opacity:
  @include mat.elevation(2);
}

@mixin contact-card-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);

  .contact-card-name {
    color: mat.get-color-from-palette($primary, lighter);
  }
  .contact-card-job-title {
    color: #444;
  }
  .contact-card-contact {
    a {
      color: #444;
    }
  }

  .contact-card-content {
    border-top: mat.get-color-from-palette($accent) solid 3px;
  }
}

@mixin contact-card-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include contact-card-color($color);
  }
}
